import Grid from "@mui/material/Grid"
import { styled } from "@mui/material/styles"

export default function Container({ children, className, style }) {
  return (
    <Wrapper className={className} style={style}>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled(Grid)`
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
`
