// This file is generated by Slice Machine. Update with care!

import CaseStudyCarousel from "./CaseStudyCarousel"
import CodeSnippet from "./CodeSnippet"
import ContactUs from "./ContactUs"
import DataSolutions from "./DataSolutions"
import ImageListing from "./ImageListing"
import ImageWithColumns from "./ImageWithColumns"
import ImageWithText from "./ImageWithText"
import LiftupCard from "./LiftupCard"
import OurCustomers from "./OurCustomers"
import Pricing from "./Pricing"
import RichText from "./RichText"
import Testimonials from "./Testimonials"
import TextOnImage from "./TextOnImage"
import TextWithText from "./TextWithText"

export {
  CaseStudyCarousel,
  CodeSnippet,
  ContactUs,
  DataSolutions,
  ImageListing,
  ImageWithColumns,
  ImageWithText,
  LiftupCard,
  OurCustomers,
  Pricing,
  RichText,
  Testimonials,
  TextOnImage,
  TextWithText,
}

export const components = {
  case_study_carousel: CaseStudyCarousel,
  code_snippet: CodeSnippet,
  contact_us: ContactUs,
  data_solutions: DataSolutions,
  image_listing: ImageListing,
  image_with_columns: ImageWithColumns,
  image_with_text: ImageWithText,
  liftup_card: LiftupCard,
  our_customers: OurCustomers,
  pricing: Pricing,
  rich_text: RichText,
  testimonials: Testimonials,
  text_on_image: TextOnImage,
  text_with_text: TextWithText,
}
