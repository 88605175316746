import React from "react"

import { styled } from "@mui/material"
import Grid from "@mui/material/Grid"

import Container from "../../components/Container"
import Heading from "../../components/Heading"
import { transformImageFormat } from "../../helper"

const OurCustomers = ({ slice }) => {
  return (
    <Container>
      <Wrapper item xs={11} md={10}>
        <Title>
          <Heading size={2}>{slice.primary.title}</Heading>
        </Title>
        <CellGrid
          container
          columns={
            slice.items.length <= 3
              ? { xs: 2, sm: 2, md: 3, lg: 3 }
              : { xs: 2, sm: 3, md: 4, lg: 5 }
          }
        >
          {slice.items.map((item, i) => (
            <Cell item key={i} xs={1} sm={1} md={1} lg={1}>
              <ImageWrapper>
                <Image src={transformImageFormat(item.image)} alt={item.image.alt} />
              </ImageWrapper>
            </Cell>
          ))}
        </CellGrid>
      </Wrapper>
    </Container>
  )
}

const CellGrid = styled(Grid)`
  justify-content: center;
`

const Cell = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Image = styled("img")`
  width: 100%;
`

const Title = styled("div")`
  margin-bottom: 2rem;
`
const ImageWrapper = styled("div")`
  display: flex;
  align-items: center;
  margin: 1.75rem 0;

  ${(p) => p.theme.breakpoints.down("md")} {
    margin: 0.75rem 0;
  }
`

const Wrapper = styled(Grid)`
  margin: 5rem auto 0;
`

export default OurCustomers
