import React from "react"

import { Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { PrismicRichText } from "@prismicio/react"

import { onMobile } from "../../styles"

const Pricing = ({ slice }) => {
  return (
    <section>
      {slice.primary.title && (
        <Title>
          <Typography variant={"h3"} as={"h3"}>
            <PrismicRichText field={slice.primary.title} />
          </Typography>
        </Title>
      )}
      <Wrapper>
        {slice.items.map((pricing) => (
          <PricingCard key={pricing.tier}>
            <Header>
              {pricing.tier}
              {pricing.mostPopular && <Stripe>Most popular</Stripe>}
            </Header>
            <Description>
              <PrismicRichText field={pricing.description} />
            </Description>
            <Divider />
            <Price>{pricing.price}</Price>
          </PricingCard>
        ))}
      </Wrapper>
    </section>
  )
}

const Wrapper = styled("div")`
  display: flex;
  gap: 1rem;

  ${onMobile} {
    flex-direction: column;
  }
`

const Title = styled("div")`
  padding-bottom: 2rem;
  text-align: center;
`
const PricingCard = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 10rem;
  max-width: 20rem;
  background: linear-gradient(
    180deg,
    #d7ecfa 0%,
    rgba(255, 255, 255, 0.44) 45.83%,
    #d7ecfa 100%
  );
  border-radius: 12px;

  &:hover,
  &:focus {
    box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.3);
  }
`

const Header = styled("div")`
  position: relative;
  padding: 2rem;
  overflow: hidden;
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
  background: linear-gradient(94.87deg, #b3c5dd 1.02%, #cad7e7 52.57%, #e5ecf4 100%);
  border-radius: 12px 12px 0 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

const Stripe = styled("div")`
  position: absolute;
  top: 1.5rem;
  right: -3.75rem;
  padding: 0.125rem 3.5rem 0.125rem 3rem;
  font-size: 0.75rem;
  background-color: white;
  transform: rotate(45deg);
`

const Divider = styled("div")`
  width: 90%;
  margin: 0 auto;
  padding: 0.5rem 0;
  border-top: 1px solid black;
`

const Description = styled("div")`
  flex: 1;
  padding: 0 0.5rem;
`

const Price = styled("div")`
  padding: 0.25rem 1rem 1rem 1rem;
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
`

export default Pricing
