import React from "react"

import { Grid, styled } from "@mui/material"

import model from "./model.json"
import MUIRichText from "../../components/MuiRichText"
import { transformImageFormat } from "../../helper"
import {
  Button,
  ButtonsBox,
  getDefaultStyles,
  Header,
  HEADER_STYLES,
  MAX_CONTAINER_WIDTH,
  onMobile,
  SubHeader,
  THEMES,
} from "../../styles"

validateThemeNames()

const ImageWithText = ({ slice, index, slices }) => {
  const columnSizes = { xs: 11, md: 5 }
  const hasButtons = (slice.items || []).length > 0
  const defaultStyles = getDefaultStyles(slice, index, slices)
  return (
    <FullWidthContainer style={defaultStyles}>
      <Wrapper
        container
        style={defaultStyles}
        imagetoleft={Boolean(slice.primary.imageOnLeftSide).toString()}
      >
        <ContentColumn item {...columnSizes}>
          {slice.primary.topicTitle && (
            <TopicTitle>{slice.primary.topicTitle}</TopicTitle>
          )}
          <Header>{slice.primary.header}</Header>
          {slice.primary.subHeader && <SubHeader>{slice.primary.subHeader}</SubHeader>}
          <MUIRichText field={slice.primary.text} />
          {hasButtons && (
            <ButtonsBox>
              {slice?.items?.map((item, i) => (
                <Button item={item} key={i} />
              ))}
            </ButtonsBox>
          )}
        </ContentColumn>
        <ImageColumn item {...columnSizes}>
          <Image
            src={transformImageFormat(slice.primary.image)}
            alt={slice.primary.image.alt}
            width={slice.primary.image.dimensions.width}
            height={slice.primary.image.dimensions.height}
          />
        </ImageColumn>
      </Wrapper>
    </FullWidthContainer>
  )
}

const FullWidthContainer = styled("div")`
  height: var(--height);
  background-color: var(--background-color);
`

const Wrapper = styled(Grid)`
  display: flex;
  flex-direction: ${(p) => (p.imagetoleft === "false" ? "row-reverse" : "row")};
  gap: 2rem;
  align-items: center;
  justify-content: center;
  max-width: ${MAX_CONTAINER_WIDTH};
  width: 100%;
  margin: 0 auto;
  padding: var(--wrapper-padding);
  color: var(--text-color);
  ${onMobile} {
    padding: var(--mobile-wrapper-padding);
  }
`

const ContentColumn = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 + p {
    margin-top: 2rem;
  }

  h2 + p {
    margin-top: 3rem;
  }

  ${onMobile} {
    width: 100%;
  }

  a:last-of-type {
    align-self: var(--button-alignment);
  }
`

const ImageColumn = styled(Grid)`
  overflow: hidden;

  ${onMobile} {
    align-items: center;
    margin-top: 2rem;
  }
`

const Image = styled("img")`
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
`

// TODO: consider using same values for the entire application.
//  maybe by creating separate components

const TopicTitle = styled("div")`
  margin-bottom: 2rem;
  color: var(--topic-title-color);
  font-size: 1.5rem;
  text-transform: uppercase;
`

function validateThemeNames() {
  let inCSS = JSON.stringify(Object.keys(THEMES).sort())
  let inPrismic = JSON.stringify(
    model.variations[0].primary.theme.config.options.sort()
  )
  if (inCSS !== inPrismic) {
    throw new Error(
      `Theme names in Prismic dont match themes in the code\n${inCSS}\n${inPrismic}`
    )
  }

  inCSS = JSON.stringify(Object.keys(HEADER_STYLES).sort())
  inPrismic = JSON.stringify(
    model.variations[0].primary.headerSize.config.options.sort()
  )
  if (inCSS !== inPrismic) {
    throw new Error(
      `Header size values in Prismic dont match sizes in the code\n${inCSS}\n${inPrismic}`
    )
  }
}

export default ImageWithText
