import React, { useRef, useState } from "react"

import { Grid, styled, Typography } from "@mui/material"
import { PrismicRichText } from "@prismicio/react"
import { Splide, SplideSlide } from "@splidejs/react-splide"

import LinkButton from "../../components/LinkButton"
import { onMobile } from "../../styles"
import { getPathname } from "../../utils"

const CaseStudyCarousel = ({ slice }) => {
  const items = slice.items

  const splide = useRef()

  const icons = items.map((item) => item.smallLogo)
  const [currentItem, setCurrentItem] = useState(items[0])

  function onSlideUpdated() {
    setCurrentItem(items[splide.current.splide.index])
  }

  return (
    <Container>
      <Splide
        onClick={onSlideUpdated}
        onMoved={onSlideUpdated}
        options={{ rewind: true, perPage: 1 }}
        ref={splide}
      >
        {items.map((item, index) => (
          <Slide key={index} item={item} />
        ))}
      </Splide>
      <IconListing icons={icons} activeItem={currentItem} spide={splide} />
    </Container>
  )
}

function Slide({ item }) {
  const columnBreakpoints = { xs: 12, md: 5 }

  return (
    <Card>
      <Grid container justifyContent={"center"} alignItems={"center"} spacing={5}>
        <Grid item {...columnBreakpoints}>
          <Image src={item.bigLogo.url} alt={item.bigLogo.alt || ""} />
        </Grid>
        <Grid item container {...columnBreakpoints}>
          <Typography variant={"h3"}>{item.title}</Typography>
          <Content variant={"p"}>
            <PrismicRichText field={item.description} />
          </Content>
          <Grid>
            <LinkButton href={getPathname(item.link)}>Read more</LinkButton>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

function IconListing({ icons, activeItem, spide }) {
  const ActiveIcon = {
    filter: "drop-shadow(8px 8px 10px gray)",
  }

  return (
    <div>
      <hr />
      <Wrapper>
        {icons.map((item, index) => (
          <Icon
            onClick={() => spide.current.go(index)}
            style={item === activeItem.smallLogo ? ActiveIcon : {}}
            key={index}
            src={item.url}
            alt={item.alt}
          />
        ))}
      </Wrapper>
      <hr />
    </div>
  )
}

const Image = styled("img")`
  width: 100%;
  height: auto;
  object-fit: contain;
`

const Icon = styled("img")`
  height: 24px;
  object-fit: contain;
  cursor: pointer;
  filter: grayscale(100%);
`

const Card = styled(SplideSlide)`
  padding: 3rem 0rem;
`
const Container = styled("div")`
  ${onMobile} {
    & > div > div > button.splide__arrow {
      display: none;
    }
  }
`

const Content = styled(Typography)`
  margin: 2rem 0;
`

const Wrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: space-around;

  ${onMobile} {
    padding: 1rem;
  }
`

export default CaseStudyCarousel
