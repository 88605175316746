import React from "react"

import { Box, Grid, styled } from "@mui/material"

import MUIRichText from "../../components/MuiRichText"
import { transformImageFormat } from "../../helper"
import {
  COLORS,
  getDefaultStyles,
  MAX_CONTAINER_WIDTH,
  onDesktopAndAbove,
  onMobile,
  THEMES,
} from "../../styles"

const ImageWithColumns = ({ slice, index, slices }) => {
  let styles = getDefaultStyles(slice, index, slices)
  if (index === 0) {
    styles = { ...styles, ...THEMES["darkpurple"] }
    styles["--text-color"] = COLORS.WHITE
  } else if (index % 2 === 0) {
    styles = { ...styles, ...THEMES["white"] }
  } else {
    styles = { ...styles, ...THEMES["sand"] }
  }
  // add bottom space for the last slice
  if (index === slices.length - 1) {
    styles["--wrapper-padding"] = "4.5rem 0 9rem 0"
  }

  return (
    <FullWidthContainer style={styles}>
      <Wrapper container style={styles}>
        <Grid item xs={11} sm={11} md={7}>
          {slice.primary.subheader && (
            <TopicTitle>{slice.primary.subheader}</TopicTitle>
          )}
          <TitleWrapper>
            <MUIRichText field={slice.primary.title} />
          </TitleWrapper>
          <Image
            src={transformImageFormat(slice.primary.image)}
            alt={slice.primary.image.alt}
            width={slice.primary.image.dimensions.width}
            height={slice.primary.image.dimensions.height}
          />
          <TextColumns>
            <MUIRichText field={slice.primary.content} />
          </TextColumns>
        </Grid>
      </Wrapper>
    </FullWidthContainer>
  )
}

const FullWidthContainer = styled("div")`
  height: var(--height);
  background-color: var(--background-color);
`

const Wrapper = styled(Grid)`
  display: flex;
  flex-direction: var(--content-direction);
  gap: 2rem;
  align-items: center;
  justify-content: center;
  max-width: ${MAX_CONTAINER_WIDTH};
  margin: 0 auto;
  padding: var(--wrapper-padding);
  color: var(--text-color);
  ${onMobile} {
    padding: var(--mobile-wrapper-padding);
  }
`

const TitleWrapper = styled(Box)`
  width: 80%;
  margin: 0 auto 1rem auto;
  text-align: center;
  font-size: 2.5rem;

  ${onMobile} {
    width: 100%;
    h1,
    h2 {
      font-size: 2.5rem;
    }
  }

  ${onDesktopAndAbove} {
    width: 60%;
    font-size: 4rem;
    margin: 0 auto 2rem auto;
  }
`

const TextColumns = styled(Box)`
  columns: 2;
  column-gap: 3rem;
  margin-top: 2rem;

  ${onMobile} {
    columns: 1;
  }

  p:first-of-type {
    margin-top: 0;
  }

  a,
  a:visited {
    color: #74a1de;
  }
`

const Image = styled("img")`
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
`

const TopicTitle = styled("div")`
  color: ${COLORS.WHITE};
  font-size: 1.5rem;
  text-transform: uppercase;
  margin: 0 auto 1rem auto;
  text-align: center;
`

export default ImageWithColumns
