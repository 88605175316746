import React from "react"

import { Box, styled } from "@mui/material"

import MUIRichText from "../../components/MuiRichText"
import {
  Button,
  getDefaultStyles,
  Header,
  MAX_CONTAINER_WIDTH,
  onMobile,
  onTablet,
  SubHeader,
} from "../../styles"

const TextOnImage = ({ slice, index, slices }) => {
  const defaultStyles = getDefaultStyles(slice, index, slices)

  return (
    <Container style={defaultStyles}>
      <Wrapper slice={slice}>
        <Content index={index}>
          <CenteredHeader>{slice.primary.header}</CenteredHeader>
          <SubHeader>{slice.primary.subheader}</SubHeader>
          <MUIRichText field={slice.primary.text} />
          <ButtonContainer>
            <Button item={slice.primary} />
          </ButtonContainer>
        </Content>
      </Wrapper>
    </Container>
  )
}

const Container = styled(Box)`
  width: 100%;
  background-color: black;
`

const Content = styled(Box)`
  width: 50%;
  ${(props) => `padding: ${props.index === 0 ? "9rem 4rem 4rem" : "4.5rem 4rem"};`}
  color: var(--text-color);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
  h1 + p {
    margin-top: 2rem;
  }

  h2 + p {
    margin-top: 3rem;
  }

  ${onTablet} {
    width: 100%;
    ${(props) => `padding: ${props.index === 0 ? "7rem 1rem 3rem" : "4rem 1rem"};`}
  }
`

const Wrapper = styled(Box)`
  ${(props) => `background-image: url(${props.slice.primary.backgroundImage.url});`}
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: ${MAX_CONTAINER_WIDTH};
  margin: 0 auto;
`

const CenteredHeader = styled(Header)`
  text-align: center;
  ${onMobile} {
    text-align: left;
  }
`

const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  padding-top: 6rem;

  a {
    padding: 10px 6rem;
  }
`

export default TextOnImage
