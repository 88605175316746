import React from "react"

import { Grid, styled } from "@mui/material"

import MuiRichText from "../../components/MuiRichText"
import { getDefaultStyles, MAX_CONTAINER_WIDTH, onMobile } from "../../styles"

const RichText = ({ slice, index, slices }) => {
  const defaultStyles = getDefaultStyles(slice, index, slices)
  return (
    <Wrapper container style={defaultStyles}>
      <Grid item xs={11} md={10.2}>
        <MuiRichText field={slice.primary.content} />
      </Grid>
    </Wrapper>
  )
}

// TODO: fix spacing between slices. The current implementation
//  adds top a bottom padding to slices which doubles the spacing
//  between slices. Check getDefaultStyles() function.
//  Replace padding to var(--wrapper-padding) and var(--mobile-wrapper-padding)
//  for desktop and mobile respectively when this issue is resolved.

const Wrapper = styled(Grid)`
  display: flex;
  flex-direction: var(--content-direction);
  align-items: center;
  justify-content: center;
  max-width: ${MAX_CONTAINER_WIDTH};
  margin: 0 auto;
  padding: 0 0 4.5rem 0;
  color: var(--text-color);
  ${onMobile} {
    padding: 0 0 4rem 0;
    margin: none;
  }
`

export default RichText
