import Link from "next/link"

import Button from "./Button"

export default function LinkButton({ href, variant = "contained", ...rest }) {
  const Component = href?.startsWith("http") ? AbsolutePath : RelativePath
  return <Component href={href} variant={variant} {...rest} />
}

function RelativePath({ href, children, ...rest }) {
  return (
    <Link href={href} passHref>
      <a>
        <Button {...rest}>{children}</Button>
      </a>
    </Link>
  )
}

function AbsolutePath({ href, children, ...rest }) {
  return (
    <Button href={href} component="a" {...rest}>
      {children}
    </Button>
  )
}
