import React from "react"

import { Grid, styled } from "@mui/material"

import MUIRichText from "../../components/MuiRichText"
import {
  Button,
  ButtonsBox,
  getDefaultStyles,
  Header,
  MAX_CONTAINER_WIDTH,
  onMobile,
} from "../../styles"

const TextWithText = ({ slice, index, slices }) => {
  const defaultStyles = getDefaultStyles(slice, index, slices)
  const columnSizes = { xs: 11, md: 5 }
  const Buttons = slice.items
  const leftBlockButtons = Buttons.filter((item) => item.position === "left")
  const rightBlockButtons = Buttons.filter((item) => item.position === "right")
  const hasButtons = (slice.items || []).length > 0
  const hasLeftBlockButtons = (leftBlockButtons || []).length > 0
  const hasRightBlockButtons = (rightBlockButtons || []).length > 0

  return (
    <FullWidthContainer style={defaultStyles}>
      <Wrapper container style={defaultStyles}>
        <Content item {...columnSizes}>
          <Header>{slice.primary.leftBlockHeader}</Header>
          <MUIRichText field={slice.primary.leftBlockContent} />
          {hasButtons && hasLeftBlockButtons && (
            <ButtonsBox>
              {leftBlockButtons.map((item) => (
                <Button item={item} key={item.buttonText} />
              ))}
            </ButtonsBox>
          )}
        </Content>

        <Content item {...columnSizes}>
          <Header>{slice.primary.rightBlockHeader}</Header>
          <MUIRichText field={slice.primary.rightBlockContent} />
          {hasButtons && hasRightBlockButtons && (
            <ButtonsBox>
              {rightBlockButtons.map((item) => (
                <Button item={item} key={item.buttonText} />
              ))}
            </ButtonsBox>
          )}
        </Content>
      </Wrapper>
    </FullWidthContainer>
  )
}

const FullWidthContainer = styled("div")`
  height: var(--height);
  background-color: var(--background-color);
`

const Content = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 2rem;

  h1 + p {
    margin-top: 2rem;
  }

  h2 + p {
    margin-top: 3rem;
  }

  ${onMobile} {
    width: 100%;
    padding-right: 0;
  }

  a:last-of-type {
    align-self: var(--button-alignment);
  }
`

const Wrapper = styled(Grid)`
  display: flex;
  flex-direction: var(--content-direction);
  gap: 2rem;
  align-items: flex-start;
  justify-content: center;
  max-width: ${MAX_CONTAINER_WIDTH};
  width: 100%;
  margin: 0 auto;
  padding: var(--wrapper-padding);
  color: var(--text-color);
  ${onMobile} {
    padding: var(--mobile-wrapper-padding);
  }
`

export default TextWithText
