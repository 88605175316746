import React from "react"

import { Grid, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { PrismicRichText } from "@prismicio/react"

import LinkButton from "../../components/LinkButton"
import { onMobile } from "../../styles"

const STYLE = {
  "default-slice": {
    "--text-align": "center",
    "--padding": "2rem 0 4rem 0", // visual centering
    "--align-button": "center",
  },
  longText: {
    "--text-align": "left",
    "--padding": "1rem",
    "--align-button": "flex-end",
  },
}

const LiftupCard = ({ slice }) => {
  const style = STYLE[slice.variation]
  if (!style) {
    throw `Unknown slice type: ${slice.variation}`
  }

  const descriptionBreakpoints = { xs: 12, md: 6 }
  if (slice.variation === "longText") {
    descriptionBreakpoints.md = 12
  }

  return (
    <Grid container justifyContent={"center"}>
      <Wrapper
        item
        container
        xs={12}
        md={10}
        justifyContent={"center"}
        spacing={2}
        style={style}
      >
        {slice.primary.title && (
          <Grid container item xs={10} justifyContent={"center"}>
            <Typography variant={"h3"} as={"h3"}>
              <PrismicRichText field={slice.primary.title} />
            </Typography>
          </Grid>
        )}

        <ContentRow
          container
          item
          {...descriptionBreakpoints}
          justifyContent={"center"}
        >
          <div>
            <PrismicRichText field={slice.primary.description} />
          </div>
        </ContentRow>

        {slice.primary.link && slice.primary.linkText && (
          <ButtonRow container item xs={11} md={12}>
            <LinkButton href={slice.primary.link.url}>
              {slice.primary.linkText}
            </LinkButton>
          </ButtonRow>
        )}
      </Wrapper>
    </Grid>
  )
}

const Wrapper = styled(Grid)`
  margin-left: 0;
  padding: var(--padding);
  text-align: var(--text-align);
  background: linear-gradient(
    94.87deg,
    rgba(179, 197, 221, 0.294) 1.02%,
    rgba(202, 215, 231, 0.282) 52.57%,
    rgba(229, 236, 244, 0.264) 100%
  );
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  ${onMobile} {
    padding: 1rem 0;
  }
`

const ContentRow = styled(Grid)`
  ${onMobile} {
    padding: 1rem;
  }
`

const ButtonRow = styled(Grid)`
  justify-content: var(--align-button);
`

export default LiftupCard
