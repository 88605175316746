import React from "react"

import { Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { PrismicRichText } from "@prismicio/react"
import Link from "next/link"

import { transformImageFormat } from "../../helper"
import { onMobile } from "../../styles"
import { getPathname } from "../../utils"

const ImageListing = ({ slice }) => (
  <Container>
    {slice.primary.title && (
      <Title>
        <Typography variant={"h3"} as={"h3"}>
          <PrismicRichText field={slice.primary.title} />
        </Typography>
      </Title>
    )}
    <hr />
    <Wrapper>
      {slice?.items?.map((item, index) => (
        <Link key={index} href={getPathname(item.link)} passHref>
          <a>
            <Image src={transformImageFormat(item.logo)} alt={item.logo.alt} />
          </a>
        </Link>
      ))}
    </Wrapper>
    <hr />
  </Container>
)

const Container = styled("section")`
  width: 100%;
`

const Title = styled("div")`
  padding-bottom: 2rem;
  text-align: center;
`
const Wrapper = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: space-around;

  ${onMobile} {
    padding: 1rem;
  }
`

const Image = styled("img")`
  width: 100%;
  height: auto;
  object-fit: contain;
`

export default ImageListing
