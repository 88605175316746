import React from "react"

import { Grid, styled, Typography } from "@mui/material"

import { transformImageFormat } from "../../helper"
import { onMobile, onTablet } from "../../styles"

const blockSizes = { xs: 6, md: 5, lg: 4, xl: 3 }
const rowSpacing = { xs: "0.5rem", md: "2.5rem" }

const BLOCK_STYLES = {
  left: {
    "--flex-direction": "row-reverse",
    "--icon-left-margin": "auto",
  },
  right: {
    "--flex-direction": "row",
    "--icon-left-margin": 0,
  },
}

function Block({ title, description, icon, left = false }) {
  const styles = BLOCK_STYLES[left ? "left" : "right"]

  return (
    <StyledBlock style={styles}>
      <IconWrapper>
        <Icon src={icon.url} alt={icon.alt} />
      </IconWrapper>
      <BlockContent>
        <div>{title}</div>
        <div>{description}</div>
      </BlockContent>
    </StyledBlock>
  )
}

const Row = ({ left, right, isEnclosing = false }) => (
  <RowWrapper container spacing={rowSpacing} justifyContent={"center"}>
    <Grid item {...blockSizes}>
      <Block {...left} left />
    </Grid>
    {!isEnclosing && <Spacer item md={2} />}
    <Grid item {...blockSizes}>
      <Block {...right} />
    </Grid>
  </RowWrapper>
)

const DataSolutions = ({ slice }) => {
  const img = slice.primary.backgroundImage

  return (
    <Wrapper>
      <Header variant={"h1"} as={"h1"}>
        {slice.primary.title}
      </Header>

      <BackgroundImageWrapper>
        <BackgroundImage src={transformImageFormat(img)} alt={img.alt} />
      </BackgroundImageWrapper>

      <Row left={slice.items[0]} right={slice.items[1]} isEnclosing />
      <Row left={slice.items[2]} right={slice.items[3]} />
      <Row left={slice.items[4]} right={slice.items[5]} />
      <Row left={slice.items[6]} right={slice.items[7]} isEnclosing />

      <Header variant={"h1"} as={"h1"}>
        {slice.primary.footer}
      </Header>
    </Wrapper>
  )
}

const Wrapper = styled("div")`
  position: relative;
  isolation: isolate;
`

const RowWrapper = styled(Grid)`
  margin-bottom: 2.5rem;

  ${onTablet} {
    margin-bottom: 1rem;
  }

  ${onMobile} {
    margin-bottom: 0.5rem;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

const BackgroundImageWrapper = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  width: 70%;
  max-width: 45rem;
  transform: translate(-50%, -50%);

  ${onMobile} {
    display: none;
  }
`

const BackgroundImage = styled("img")`
  width: 100%;
  height: 100%;
`
const Header = styled(Typography)`
  padding: 2rem;
  text-align: center;
`

const Icon = styled("img")`
  width: 70%;
  height: 70%;
`

const IconWrapper = styled("div")`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  margin-left: var(--icon-left-margin);
  background-color: white;
  border-radius: 50%;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);

  ${onMobile} {
    margin: 0 auto;
  }
`

const BlockContent = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  div:first-of-type {
    padding-bottom: 0.5rem;
    font-weight: bold;
    font-size: 1.25rem;
  }
`

const StyledBlock = styled("div")`
  display: flex;
  flex-direction: var(--flex-direction);
  gap: 1rem;
  align-items: center;
  height: 100%;
  min-height: 8rem;
  padding: 1rem;
  color: white;
  background-color: black;
  border-radius: calc(12 / 16 * 1rem);

  ${onMobile} {
    flex-direction: column;
    min-height: 10rem;
  }

  ${onTablet} {
    min-height: 10rem;
  }
`

const Spacer = styled(Grid)`
  ${onTablet} {
    display: none;
  }
`

export default DataSolutions
