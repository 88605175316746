import React from "react"

import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Grid, styled, Typography } from "@mui/material"

import Button from "../../components/Button"
import MUIRichText from "../../components/MuiRichText"
import { transformImageFormat } from "../../helper"
import { COLORS, MAX_CONTAINER_WIDTH } from "../../styles"

const STYLE = {
  "--background-color": COLORS.GRANITE,
  "--text-color": COLORS.SAND,
  "--topic-title-color": COLORS.WHITE,
}

const Testimonials = ({ slice }) => {
  const testimonialList = slice.items || []

  const [showAllTestimonials, setShowAllTestimonials] = React.useState(false)

  const testimonialItemsToRender = showAllTestimonials
    ? testimonialList
    : testimonialList.slice(0, 4)

  return (
    <FullWidthContainer style={STYLE}>
      <Wrapper container style={STYLE}>
        <Grid item xs={11} md={10}>
          <Title variant={"h2"}>Testimonials</Title>
          <Main item container rowSpacing={"4rem"} columnSpacing={"8rem"}>
            {testimonialItemsToRender.map((item, index) => (
              <Card key={index} item={item} />
            ))}
          </Main>
          {testimonialList.length > 4 && (
            <TestimonialButton
              variant={"outlined"}
              onClick={() => setShowAllTestimonials(!showAllTestimonials)}
              endIcon={showAllTestimonials ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
              {showAllTestimonials ? "less" : "more"}
            </TestimonialButton>
          )}
        </Grid>
      </Wrapper>
    </FullWidthContainer>
  )
}

const Card = ({ item }) => {
  const columnSizes = { xs: 11, sm: 5.5, md: 6 }
  return (
    <Grid container item {...columnSizes}>
      <Person item container>
        <Grid>
          <Image
            src={transformImageFormat(item.photo)}
            alt={item?.photo?.alt}
            width={item?.photo?.dimensions.width}
            height={item?.photo?.dimensions.height}
          />
        </Grid>
        <InfoSection>
          <Name variant={"h5"}>{item.name}</Name>
          <MUIRichText field={item.jobPosition} />
        </InfoSection>
      </Person>
      <Testimonial item>
        <MUIRichText field={item.testimonial} />
      </Testimonial>
    </Grid>
  )
}

const FullWidthContainer = styled("div")`
  height: auto;
  background-color: var(--background-color);
`

const Main = styled(Grid)`
  justify-content: start;
`

const Wrapper = styled(Grid)`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  max-width: ${MAX_CONTAINER_WIDTH};
  margin: 0 auto;
  padding: 2rem 0 4rem 0;
  color: var(--text-color);
`

const Title = styled(Typography)`
  margin: 3rem 0;
  color: var(--topic-title-color);
  font-weight: 700;
  font-size: 2.5rem;
`

const Person = styled(Grid)`
  flex-direction: row;
  gap: 2rem;
  align-items: center;
`

const InfoSection = styled(Grid)`
  color: var(--topic-title-color);
  a {
    color: var(--topic-title-color);
  }
  p {
    margin: 0;
    padding: 0;
  }
`

const Testimonial = styled(Grid)`
  margin-top: 1.2rem;
  color: var(--topic-title-color);
  font-weight: 400;
  font-size: 1rem;
  font-style: italic;
`

const Image = styled("img")`
  width: 95px;
  height: 95px;
  border: 3.8px solid #c4c4c4;
  border-radius: 50px;
`

const TestimonialButton = styled(Button)`
  margin: 1rem 0;
  color: var(--text-color);
  background: none;
  border-color: var(--text-color);
  &:hover,
  &:focus {
    color: var(--text-color);
    background-color: inherit;
    border-color: var(--text-color);
  }
`

const Name = styled(Typography)`
  min-width: 15rem;
  font-weight: 700;
  font-size: 1.2rem;
`

export default Testimonials
