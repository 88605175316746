import React, { useEffect } from "react"

import { PrismicRichText } from "@prismicio/react"
import Prism from "prismjs"
import styled from "styled-components"
import "prismjs/components/prism-javascript.min"
import "prismjs/components/prism-json.min"
import "prismjs/components/prism-json5.min"
import "prismjs/components/prism-python.min"
import "prismjs/components/prism-typescript.min"
import "prismjs/components/prism-bash.min"

import "prismjs/themes/prism-tomorrow.min.css"

export const supportedLangs = [
  "bash",
  "javascript",
  "js",
  "json",
  "jsx",
  "py",
  "python",
  "shell",
  "ts",
  "tsx",
  "typescript",
]

export function isLangSupported(lang) {
  return supportedLangs.indexOf(lang) !== -1
}

const CodeSnippet = ({ slice }) => {
  useEffect(() => {
    Prism.highlightAll()
  }, [])

  return (
    <Wrapper>
      <code className={`language-${slice.primary.language}`}>
        <PrismicRichText field={slice.primary.code} />
      </code>
    </Wrapper>
  )
}

const Wrapper = styled.pre`
  border-radius: 5px;

  &[class*="language-"] {
    background-color: #000000;
  }
`

export default CodeSnippet
