import React from "react"

import { Grid, styled } from "@mui/material"
import { PrismicRichText } from "@prismicio/react"

import MUIRichText from "../../components/MuiRichText"
import { transformImageFormat } from "../../helper"
import { COLORS, MAX_CONTAINER_WIDTH, onMobile } from "../../styles"

const THEMES = {
  dark: {
    "--background-color": COLORS.GRANITE,
    "--text-color": COLORS.SAND,
    "--topic-title-color": COLORS.WHITE,
  },
  black: {
    "--background-color": COLORS.NIGHT_SKY,
    "--text-color": COLORS.SAND,
    "--topic-title-color": COLORS.WHITE,
  },
}

function ContactUs({ slice, index, slices }) {
  const isLastSlice = index === slices.length - 1
  const columnSizes = { xs: 11, md: 5 }
  const STYLE = {
    ...THEMES[slice.primary.theme],
    "--wrapper-padding": index === 0 ? "8rem 0 4rem 0" : "4rem 0",
    "--height": isLastSlice ? "100%" : "auto",
  }

  return (
    <FullWidthContainer style={STYLE}>
      <Wrapper container style={STYLE}>
        <ContentColumn item {...columnSizes}>
          <TopicTitle id="contact">{slice.primary.subheader}</TopicTitle>
          <MUIRichText field={slice.primary.description} />
          <ContactsGrid container spacing={2}>
            {slice?.items?.map((col, i) => (
              <Grid item key={i} xs={12} sm={col.columnSize * 6}>
                <PrismicRichText field={col.content} />
              </Grid>
            ))}
          </ContactsGrid>
        </ContentColumn>
        <ImageColumn item {...columnSizes}>
          <Image
            src={transformImageFormat(slice.primary.image)}
            alt={slice.primary.image.alt}
            width={slice.primary.image.dimensions.width}
            height={slice.primary.image.dimensions.height}
          />
        </ImageColumn>
      </Wrapper>
    </FullWidthContainer>
  )
}

const FullWidthContainer = styled("div")`
  height: var(--height);
  background-color: var(--background-color);
`

const Wrapper = styled(Grid)`
  display: flex;
  flex-direction: var(--content-direction);
  gap: 2rem;
  justify-content: center;
  max-width: ${MAX_CONTAINER_WIDTH};
  margin: 0 auto;
  padding: var(--wrapper-padding);
  color: var(--text-color);
`

const ContentColumn = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${onMobile} {
    width: 100%;
  }
`

const ContactsGrid = styled(Grid)`
  a {
    color: ${COLORS.SEA_3};
  }
`

const ImageColumn = styled(Grid)`
  overflow: hidden;

  ${onMobile} {
    align-items: center;
    margin-top: 2rem;
  }
`

const Image = styled("img")`
  display: block;
  width: 100%;
  margin: 0 auto;
`

const TopicTitle = styled("div")`
  margin-bottom: 3rem;
  color: var(--topic-title-color);
  font-weight: 400;
  font-size: 1.7rem;
  text-transform: uppercase;
`

export default ContactUs
